import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import router from './router.js'
// import { BootstrapVue } from 'bootstrap-vue'
import VueAxios from 'vue-axios'
import axios from './remote/axios'
import store from './store/index.js'
import VueSweetalert2 from 'vue-sweetalert2';
import VueToast from 'vue-toast-notification';

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'sweetalert2/dist/sweetalert2.min.css';
import './main.css'
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.config.productionTip = false

Vue.use(VueMeta)
Vue.use(VueToast, {
  // One of the options
  position: 'bottom'
})
// Vue.use(BootstrapVue)
Vue.use(VueAxios, axios)

const swalOption = {
  confirmButtonColor: '#F2994A',
  cancelButtonColor: '#dc3545',
};

Vue.use(VueSweetalert2, swalOption);

new Vue({
  axios,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
