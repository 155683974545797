import commonModal from '../../common-modal/common-modal.vue'

export default {
  name: 'user-info',
  metaInfo: {
    title: 'Update User Info',
  },
  components: {
    commonModal,
  },
  data() {
    return {
      isLoading: false,
      isCommonModalShow: false,
      commonModalType: 'info',
      commonModalIcon: 'common-modal-icon.svg',
      commonModalTitle: String,
      commonModalMessage: String,
      commonModalButtonText: 'Tutup',
      user: {
        id: '',
        name: {
          value: '',
          isEmpty: false
        },
        age: {
          value: '',
          isEmpty: false
        },
        domicile: {
          value: '',
          isEmpty: false,
        },
        marital_status: {
          value: 'Status Perkawinan',
          isEmpty: false,
        },
        num_of_children: {
          value: '',
          isEmpty: false,
        },
        child_age: {
          value: [],
          isEmpty: false,
        },
      }
    }
  },
  beforeRouteEnter(_, from, next) {
    if (from.name === null) next('/')
    else next()
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    getUserInfo() {
      const activationCode = this.$route.params.activationCode
      this.$http.get(`/users/info/${activationCode}`).then(res => {
        const data = res.data.data
        this.user.id = data.id,
        this.user.name.value = data.name
        this.user.age.value = data.age
        this.user.domicile.value = data.domicile
      })
    },

    isInputValid() {
      this.user.name.isEmpty = this.user.name.value === ''
      this.user.age.isEmpty = this.user.age.value === ''
      this.user.domicile.isEmpty = this.user.domicile.value === ''
      this.user.marital_status.isEmpty = this.user.marital_status.value === 'Status Perkawinan'
      this.user.num_of_children.isEmpty = this.user.num_of_children.value === ''
      this.user.child_age.isEmpty = this.user.child_age.value.length === 0

      if (this.user.name.isEmpty || this.user.age.isEmpty || this.user.domicile.isEmpty || this.user.marital_status.isEmpty) return false
      else {
        if (this.user.marital_status.value === 'MENIKAH') {
          if (this.user.num_of_children.isEmpty || this.user.child_age.isEmpty) return false
        }
        return true
      }
    },

    onCloseModal() {
      this.isCommonModalShow = false
      this.$router.push({ name: 'login' })
    },

    updateUserInfo() {
      if (this.isInputValid()) {
        const formData = new FormData()
        formData.append('id', this.user.id)
        formData.append('name', this.user.name.value)
        formData.append('age', this.user.age.value)
        formData.append('domicile', this.user.domicile.value)
        formData.append('marital_status', this.user.marital_status.value)
        if (this.user.marital_status.value === 'MENIKAH') {
          formData.append('num_of_children', this.user.num_of_children.value)

          const childAge = JSON.stringify(this.user.child_age.value)
          formData.append('child_age', childAge)
        }

        this.isLoading = true
        this.$http.put('/users/info', formData).then(() => {
          this.isLoading = false
          this.isCommonModalShow = true
          this.commonModalTitle = 'Selamat Datang, Teman Hannah!'
          this.commonModalMessage = 'Selamat datang, selamat bergabung, semangat meraih ilmu bersama Hannah Indonesia.'
        }).catch(err => {
          this.isLoading = false
          let errMsg = 'Network Error';
          if (err.response) errMsg = err.response.data.message
          this.$swal.fire({
            title: 'Oops...',
            icon: 'error',
            text: errMsg,
          })
        })
      }
    }
  }
}