import navComponent from '../../index/nav/nav.vue'
import contactUs from '../../contact-us/contact-us.vue'
import footerComponent from '../../index/footer/footer.vue'

export default {
  name: 'payment-success',
  components: {
    navComponent,
    contactUs,
    footerComponent
  },
  data() {
    return {
      showContactUsModal: false,
      commonModal: {
        isShow: false,
        type: 'info',
        icon: 'common-modal-icon.svg',
        title: String,
        message: String,
        confirmButtonText: 'Tutup',
        direction: null
      },
    }
  },
  created() {
    document.documentElement.scrollTop = 0;
    window.addEventListener('scroll', this.handleScroll);
  },
  mounted() {
    const bookId = this.$route.params.bookId
    this.getTransactionByBookId(bookId)
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 40) {
				document.getElementById('navbar').classList.add('fixed-top');
				// add padding top to show content behind navbar
				const navbar_height = document.querySelector('.navbar').offsetHeight;
				document.getElementById('payment-success').style.paddingTop = navbar_height + 'px';
			} else {
        document.getElementById('navbar').classList.remove('fixed-top');
				// remove padding top from body
				document.getElementById('payment-success').style.paddingTop = '0';
			}
    },
    getTransactionByBookId(bookId) {
      const accessToken = JSON.parse(localStorage.getItem('userData')).accessToken
      this.$http.get(`transactions/${bookId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      }).then((res) => {
        const data = res.data.data
        if (data.isSuccessRedirectUrlOpened === 0 && data.status === 'PAID') {
          this.setSuccessRedirectUrlOpened(data.id)
        } else {
          this.$router.push({ name: 'profile' })
        }
      })
    },
    setSuccessRedirectUrlOpened(transactionId) {
      const accessToken = JSON.parse(localStorage.getItem('userData')).accessToken
      const formData = new FormData()
      formData.append('id', transactionId)
      formData.append('isSuccessRedirectUrlOpened', 1)
      this.$http.put(`transactions`, formData, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      })
    },
    navigateToProfile() {
      this.$router.push({ name: 'profile' })
    },
    onContactUsClick() {
      this.showContactUsModal = true
      this.activeEl = 5
    },
    showPopupRegisterAccount() {
      if (localStorage.getItem('userData')) {
        this.showCommonModal({
          type: 'info',
          icon: 'ic-hannah-selamat-bergabung.svg',
          title: 'Terima Kasih Anda Sudah Gabung Jadi Teman Hannah',
          message: 'Anda telah berhgabung menjadi Teman Hannah, semangat meraih ilmu bersama Hannah Indonesia.',
          confirmButtonText: 'Tutup'
        })
      } else this.$router.push({ name: 'register', query: { redirect: this.$route.path } })
    },
    showCommonModal({ type, icon, title, message, confirmButtonText, direction = null}) {
      this.commonModal.isShow = true
      this.commonModal.type = type
      this.commonModal.icon = icon
      this.commonModal.title = title
      this.commonModal.message = message
      this.commonModal.confirmButtonText = confirmButtonText
      this.commonModal.direction = direction
    },
    onSendMessageSuccess() {
      this.showContactUsModal = false
      this.$swal.fire({
        title: 'Sukses',
        icon: 'success',
        text: 'Pesan berhasil dikirim',
      })
    },
    onSendMessageError(error) {
      this.showContactUsModal = false
      this.$swal.fire({
        title: 'Oops...',
        icon: 'error',
        text: error.message,
      })
    },
  }
}