import navComponent from '../index/nav/nav.vue'
import contactUs from '../contact-us/contact-us.vue'
import commonModal from '../common-modal/common-modal.vue'
import footerComponent from '../index/footer/footer.vue'
import { toIdr, countDownTimer } from '../../helper/currency-helper'

export default {
  name: 'payment',
  metaInfo: {
    title: 'Detail Payment',
  },
  components: {
    navComponent,
    contactUs,
    commonModal,
    footerComponent
  },
  data() {
    return {
      activeEl: 3,
      isOnMobile: false,
      showContactUsModal: false,
      commonModal: {
        isShow: false,
        type: 'info',
        icon: 'ic-success-register-program.svg',
        title: String,
        message: String,
        confirmButtonText: 'Tutup',
      },
      data: {
        program: {
          name: ''
        },
        transaction: {
          total: '',
          isExpired: false,
        }
      },
      countDownTimer: '00:00:00',
    }
  },
  created() {
    document.documentElement.scrollTop = 0
    window.addEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.onResize()
    const bookId = this.$route.params.id
    this.getDetailPayment(bookId)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    onResize() {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true
      } else {
        this.isOnMobile = false
      }
    },
    handleScroll() {
      if (window.scrollY > 40) {
				document.getElementById('navbar').classList.add('fixed-top');
				// add padding top to show content behind navbar
				const navbar_height = document.querySelector('.navbar').offsetHeight;
				document.getElementById('payment-detail').style.paddingTop = navbar_height + 'px';
			} else {
        document.getElementById('navbar').classList.remove('fixed-top');
				// remove padding top from body
				document.getElementById('payment-detail').style.paddingTop = '0';
			}
    },
    getDetailPayment(bookId) {
      const accessToken = JSON.parse(localStorage.getItem('userData')).accessToken
      this.$http.get(`program/registered-history/${bookId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      }).then((res) =>{
        this.data = res.data.data
        this.data.program.price = toIdr(this.data.program.price)
        this.data.program.shippingCost = toIdr(this.data.program.shippingCost)
        this.data.transaction.total = toIdr(this.data.transaction.total)
        if (!this.data.transaction.isExpired && this.data.transaction.status === 'PENDING') {
          countDownTimer(this.data.transaction.expiredAt, (hours, minutes, seconds, isExpired) => {
            const h = hours < 10 ? `0${hours}` : hours
            const m = minutes < 10 ? `0${minutes}` : minutes
            const s = seconds < 10 ? `0${seconds}` : seconds
            this.countDownTimer = `${h}:${m}:${s}`
            this.data.transaction.isExpired = isExpired
          })
        }
      }).catch((err) => {
        let errorMessage = "Network error";
        if (err.response) errorMessage = err.response.data.message
        this.$swal.fire({
          title: 'Oops...',
          icon: 'error',
          text: errorMessage,
        }).then(() => {
          window.history.back()
        })
      });
    },
    updatePaymentStatus() {
      this.commonModal.isShow = false
      const status = 'WAITING_CONFIRMATION'
      const formData = new FormData()
      formData.append('id', this.data.transaction.id)
      formData.append('status', status)

      this.$http.put('transaction/status', formData).then(() => {
        this.data.transaction.status = status
      }).catch((err) => {
        let errorMessage = "Network error";
        if (err.response) errorMessage = err.response.data.message
        this.$swal.fire({
          title: 'Oops...',
          icon: 'error',
          text: errorMessage,
        })
      });
    },
    onContactUsClick() {
      this.showContactUsModal = true
      this.activeEl = 5
    },
    showPopupRegisterAccount() {
      if (localStorage.getItem('userData')) {
        this.showCommonModal({
          type: 'info',
          icon: 'ic-hannah-selamat-bergabung.svg',
          title: 'Terima Kasih Anda Sudah Gabung Jadi Teman Hannah',
          message: 'Anda telah berhgabung menjadi Teman Hannah, semangat meraih ilmu bersama Hannah Indonesia.',
          confirmButtonText: 'Tutup'
        })
      } else this.$router.push({ name: 'register', query: { redirect: this.$route.path } })
    },
    pay() {
      window.location.href = this.data.transaction.invoiceUrl
    },
    showCommonModal({ type, icon, title, message, confirmButtonText, cancelButtonText }) {
      this.commonModal.isShow = true
      this.commonModal.type = type
      this.commonModal.icon = icon
      this.commonModal.title = title
      this.commonModal.message = message
      this.commonModal.confirmButtonText = confirmButtonText
      this.commonModal.cancelButtonText = cancelButtonText
    },
    onSendMessageSuccess() {
      this.showContactUsModal = false
      this.$swal.fire({
        title: 'Sukses',
        icon: 'success',
        text: 'Pesan berhasil dikirim',
      })
    },
    onSendMessageError(error) {
      this.showContactUsModal = false
      this.$swal.fire({
        title: 'Oops...',
        icon: 'error',
        text: error.message,
      })
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
      this.$toast.default('Copied to clipboard')
    },
    closeCommonModal() {
      this.commonModal.isShow = false
    },
  }
}