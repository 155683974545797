import navComponent from '../index/nav/nav.vue'
import contactUs from '../contact-us/contact-us.vue'
import footerComponent from '../index/footer/footer.vue'

export default {
  name: 'ourteam',
  metaInfo: {
    title: 'About Us',
  },
  components: {
    navComponent,
    contactUs,
    footerComponent
  },
  data() {
    return {
      activeEl: 2,
      selectedTab: 1,
      contactUsModal: {
        isShowing: false
      },
      founders: [],
      teams: [],
    }
  },
  created() {
    document.documentElement.scrollTop = 0;
    window.addEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.getTeamMembers();
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 40) {
				document.getElementById('navbar').classList.add('fixed-top');
				// add padding top to show content behind navbar
				const navbar_height = document.querySelector('.navbar').offsetHeight;
				document.getElementById('ourteam').style.paddingTop = navbar_height + 'px';
			} else {
        document.getElementById('navbar').classList.remove('fixed-top');
				// remove padding top from body
				document.getElementById('ourteam').style.paddingTop = '0';
			}
    },
    slideTo(tab) {
      this.selectedTab = (tab === 'tab-founder') ? 1 : 2
      document.getElementById(tab).scrollIntoView({block: 'nearest'});
    },
    onContactUsClick() {
      this.contactUsModal.isShowing = true
      this.activeEl = 5
    },

    onContactUsClose() {
      this.contactUsModal.isShowing = false
      this.activeEl = 2
    },
    getTeamMembers() {
      this.$http.get('/ourteam').then((res) => {
        const data = res.data.data.map((e) => {
          e.profilePicture = `${process.env.VUE_APP_BASE_STORAGE_URL}/ourteam/${e.profilePicture}`
          return e
        })

        this.founders = data.filter((e) => (e.position === 'Founder'))
        this.teams = data.filter((e) => (e.position !== 'Founder'))
      }).catch((err) => {
        const statusCode = err.response.status
        const message = err.response.data.message
        if (statusCode === 404) {
          this.founders = []
          this.teams = []
        } else this.$toast.error(message)
      })
    },
    goToIg(account) {
      window.open(`https://www.instagram.com/${account}`)
    },
    onSendMessageSuccess() {
      this.contactUsModal.isShowing = false
      this.$swal.fire({
        title: 'Sukses',
        icon: 'success',
        text: 'Pesan berhasil dikirim',
      })
    },
    onSendMessageError(error) {
      this.contactUsModal.isShowing = false
      this.$swal.fire({
        title: 'Oops...',
        icon: 'error',
        text: error.message,
      })
    },
  }
}