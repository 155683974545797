import { render, staticRenderFns } from "./payment.html?vue&type=template&id=2740b0a1&scoped=true&"
import script from "./payment.js?vue&type=script&lang=js&"
export * from "./payment.js?vue&type=script&lang=js&"
import style0 from "./payment.css?vue&type=style&index=0&id=2740b0a1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2740b0a1",
  null
  
)

export default component.exports