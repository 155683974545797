import Vue from 'vue'
import Vuex from 'vuex'
import login from './login/index.js'
import emailVerification from './email-verification/index.js'
import program from './program/index.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    login,
    emailVerification,
    program
  }
})