export default {
  name: 'email-verification',
  created() {
    this.verifyEmail()
  },
  methods: {
    async verifyEmail() {
      const activationCode = this.$route.params.activationCode
      const redirect = this.$route.query.redirect
      const { rc, source } = this.$route.query
      
      let status = 'success'
      try {
        await this.$http.put(`/users/verification/${activationCode}`)
        status = 'success'
      } catch(err) {
        let message = 'Server mengalami gangguan'
        if (err.response) message = err.response.data.message
        status = 'failed'
        this.$store.commit('emailVerification/setMessage', message)
      }

      const query = {
        status,
        activationCode
      }

      if (redirect !== undefined) query['redirect'] = redirect

      if (status === 'success') {
        if (rc !== undefined) {
          const q = {}
          if (source !== undefined) {
            q['ac'] = activationCode
            q['source'] = source
          }
          this.$router.push({ path: `/reset-password/${rc}`, query: q })
        } else {
          this.$router.push({ path: '/login', query })
          this.$store.commit('emailVerification/showModal')
        }
      } else {
        this.$router.push({ path: '/login', query })
        this.$store.commit('emailVerification/showModal')
      }
    }
  }
}