import navComponent from '../../nav/nav.vue'
import contactUs from '../../../contact-us/contact-us.vue'
import commonModal from '../../../common-modal/common-modal.vue'
import registerEvent from './register-event/register-event.vue'
import formAddress from '../../../profile/form-address/form-address.vue'
import footerComponent from '../../footer/footer.vue'
import { toIdr } from '../../../../helper/currency-helper'

export default {
  name: 'event-program',
  metaInfo: {
    title: 'Event',
  },
  components: {
    navComponent,
    contactUs,
    commonModal,
    registerEvent,
    formAddress,
    footerComponent
  },
  data() {
    return {
      from: null,
      activeEl: 3,
      showContactUsModal: false,
      showModalRegisterEvent: false,
      showFormAddress: false,
      commonModal: {
        isShow: false,
        type: 'info',
        icon: 'common-modal-icon.svg',
        title: String,
        message: String,
        confirmButtonText: 'Tutup',
        direction: null
      },
      events: [],
      masterclasses: [],
      btnRegisterLoading: false,
      redirect: null,
      selectedProgramId: '',
    }
  },
  beforeRouteEnter(_, from, next) {
    next(vm => {
      vm.from = from
    })
  },
  created() {
    document.documentElement.scrollTop = 0;
    window.addEventListener('scroll', this.handleScroll);
    this.getPrograms('EVENT')
    this.getPrograms('MASTERCLASS')
  },
  mounted() {
    if (this.from !== null) {
      const path = this.from.path
      const query = this.from.query
      if (path === '/login' && Object.keys(query).length !== 0) setTimeout(() => this.showCommonModal({ 
        type: 'info',
        icon: 'ic-success-register-program.svg',
        title: 'Yuk, selesaikan registrasi event mu!',
        message: 'Jangan lupa selesaikan registrasi event mu ya, agar bisa mengikuti event yg sedang berlangsung',
        confirmButtonText: 'Lanjutkan Registrasi Event'
      }), 1000)
    }
  },
  computed: {
    programUIState() {
      return this.$store.getters['program/programUIState']
    }
  },
  watch: {
    programUIState(state) {
      const { status, data } = state
      switch(status) {
        case 'LOADING':
          this.btnRegisterLoading = true
          break
        case 'SUCCESS':
          this.btnRegisterLoading = false
          if (data.total) {
            this.$router.push({ name: 'payment', params: { id: data.bookId } })
          } else {
            this.showCommonModal({
              type: 'info',
              icon: 'ic-success-register-program.svg',
              title: 'Berhasil Daftar!',
              message: 'Terima kasih telah mengambil bagian di acara yang sedang berlangsung. Semoga ilmu nya bermanfaat!',
              confirmButtonText: 'Lihat Riwayat Event',
              direction: 'profile'
            })
          }
          break
        case 'FAILED':
          this.btnRegisterLoading = false
          this.showCommonModal({
            type: 'info',
            icon: 'ic-success-register-program.svg',
            title: 'Sudah Terdaftar!',
            message: 'Anda sudah terdaftar pada program ini.',
            confirmButtonText: 'Lihat Riwayat Event',
            direction: 'profile'
          })
          break
      }
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 40) {
				document.getElementById('navbar').classList.add('fixed-top');
				// add padding top to show content behind navbar
				const navbar_height = document.querySelector('.navbar').offsetHeight;
				document.getElementById('event-program').style.paddingTop = navbar_height + 'px';
			} else {
        document.getElementById('navbar').classList.remove('fixed-top');
				// remove padding top from body
				document.getElementById('event-program').style.paddingTop = '0';
			}
    },

    onContactUsClick() {
      this.showContactUsModal = true
      this.activeEl = 5
    },

    onContactUsClose() {
      this.showContactUsModal = false
      this.activeEl = 3
    },

    showCommonModal({ type, icon, title, message, confirmButtonText, direction = null}) {
      this.commonModal.isShow = true
      this.commonModal.type = type
      this.commonModal.icon = icon
      this.commonModal.title = title
      this.commonModal.message = message
      this.commonModal.confirmButtonText = confirmButtonText
      this.commonModal.direction = direction
    },

    closeCommonModal() {
      this.commonModal.isShow = false
      if (this.commonModal.direction !== null) {
        this.$router.push({ name: this.commonModal.direction })
        this.commonModal.direction = null
      }
    },

    onRegisterEventSuccess() {
      this.showModalRegisterEvent = false
      this.showCommonModal({
        type: 'info',
        icon: 'common-modal-icon.svg',
        title: 'Selamat, anda berhasil daftar!',
        message: 'Cek <b>kotak masuk/spam email</b> kamu untuk mendapat informasi detil seputar event ini yaa!',
        confirmButtonText: 'Tutup'
      })
    },

    onRegisterClick(event) {
      this.selectedProgramId = event.id
      const userData = localStorage.getItem('userData')
      if (event.limited) {
        this.showModalRegisterEvent = true
      } else {
        if (!userData) this.$router.push({ name: 'login', query: { redirect: this.$route.path } })
        else {
          if (event.withMerchandise) this.showFormAddress = true
          else this.$store.dispatch('program/registerProgram', { id: event.id })
        }
      }
    },

    onAddAddressSuccess(notes) {
      this.showFormAddress = false
      this.$store.dispatch('program/registerProgram', { id: this.selectedProgramId, notes })
    },

    showPopupRegisterAccount() {
      if (localStorage.getItem('userData')) {
        this.showCommonModal({
          type: 'info',
          icon: 'ic-hannah-selamat-bergabung.svg',
          title: 'Terima Kasih Anda Sudah Gabung Jadi Teman Hannah',
          message: 'Anda telah berhgabung menjadi Teman Hannah, semangat meraih ilmu bersama Hannah Indonesia.',
          confirmButtonText: 'Tutup'
        })
      } else this.$router.push({ name: 'register', query: { redirect: this.$route.path } })
    },

    getPrograms(type) {
      this.$http.get(`/program?type=${type}&isListed=1`).then((res) => {
        const data = res.data.data.map((e) => {
          e.isDefaultPoster = e.poster === 'default_event.png'
          e.poster = `${process.env.VUE_APP_BASE_STORAGE_URL}/${e.poster}`
          e.price = e.price === 0 ? 'Free' : `IDR ${toIdr(e.price)}`
          return e
        })

        if (type === 'EVENT') this.events = data
        else this.masterclasses = data
      }).catch((err) => {
        console.log(err);
      })
    },

    onEventClick(eventId) {
      this.$router.push({ path: `/program/event/${eventId}` })
    },

    onSendMessageSuccess() {
      this.showContactUsModal = false
      this.$swal.fire({
        title: 'Sukses',
        icon: 'success',
        text: 'Pesan berhasil dikirim',
      })
    },
    
    onSendMessageError(error) {
      this.showContactUsModal = false
      this.$swal.fire({
        title: 'Oops...',
        icon: 'error',
        text: error.message,
      })
    },
  }
}