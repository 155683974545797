export default {
  name: 'form-address',
  data() {
    return {
      isLoading: false,
      isResponseError: false,
      errorMessage: '',
      accessToken: null,
      isAddressAvailable: false,
      payload: {
        province: {
          value: '',
          isEmpty: false
        },
        city: {
          value: '',
          isEmpty: false
        },
        district: {
          value: '',
          isEmpty: false
        },
        postalCode: {
          value: '',
          isEmpty: false
        },
        detailAddress: {
          value: '',
          isEmpty: false
        },
        notes: null
      }
    }
  },
  created() {
    this.accessToken = JSON.parse(localStorage.getItem('userData')).accessToken
    this.getUserAddress()
  },
  methods: {
    getUserAddress() {
      this.$http.get('/users/address', {
        headers: {
          'Authorization': `Bearer ${this.accessToken}`
        }
      }).then(res => {
        this.isAddressAvailable = true
        const data = res.data.data
        this.payload.province.value = data.province
        this.payload.city.value = data.city
        this.payload.district.value = data.district
        this.payload.detailAddress.value = data.detailAddress
        this.payload.postalCode.value = data.postalCode
      }).catch(() => {
        this.isAddressAvailable = false
      })
    },
    isInputValid() {
      this.payload.province.isEmpty = this.payload.province.value === ''
      this.payload.city.isEmpty = this.payload.city.value === ''
      this.payload.district.isEmpty = this.payload.district.value === ''
      this.payload.postalCode.isEmpty = this.payload.postalCode.value === ''
      this.payload.detailAddress.isEmpty = this.payload.detailAddress.value === ''

      if (this.payload.province.isEmpty || this.payload.city.isEmpty || this.payload.district.isEmpty || this.payload.postalCode.isEmpty || this.payload.detailAddress.isEmpty) {
        return false
      }

      return true
    },
    submit() {
      this.isResponseError = false
      if (this.isInputValid()) {
        this.isLoading = true
        const formData = new FormData()
        formData.append('province', this.payload.province.value)
        formData.append('city', this.payload.city.value)
        formData.append('district', this.payload.district.value)
        formData.append('detail_address', this.payload.detailAddress.value)
        formData.append('postal_code', this.payload.postalCode.value)
        if (!this.isAddressAvailable) {
          this.$http.post('/users/address', formData, {
            headers: {
              'Authorization': `Bearer ${this.accessToken}`
            }
          }).then(() => {
            this.isLoading = false
            this.$emit('onSuccess', this.payload.notes)
          }).catch((err) => {
            this.isLoading = false
            this.isResponseError = true
            this.errorMessage = err.response.data.message
          })
        } else {
          this.$http.put('/users/address', formData, {
            headers: {
              'Authorization': `Bearer ${this.accessToken}`
            }
          }).then(() => {
            this.isLoading = false
            this.$emit('onSuccess', this.payload.notes)
          }).catch((err) => {
            this.isLoading = false
            this.isResponseError = true
            this.errorMessage = err.response.data.message
          })
        }
      }
    }
  }
}