import axios from 'axios'

const BASE_API_URL = process.env.VUE_APP_BASE_API_URL

export default axios.create({
  baseURL: BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
    // 'Authorization': `Bearer ${localStorage.getItem('token')}`
  }
})