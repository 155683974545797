import navComponent from '../nav/nav.vue'
import contactUs from '../../contact-us/contact-us.vue'
import footerComponent from '../footer/footer.vue'

export default {
  name: 'not-found-page',
  components: {
    navComponent,
    contactUs,
    footerComponent
  },
  data() {
    return {
      showContactUsModal: false
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    refresh: () => location.reload(),
    handleScroll() {
      if (window.scrollY > 40) {
				document.getElementById('navbar').classList.add('fixed-top');
				// add padding top to show content behind navbar
				const navbar_height = document.querySelector('.navbar').offsetHeight;
				document.getElementById('not-found').style.paddingTop = navbar_height + 'px';
			} else {
        document.getElementById('navbar').classList.remove('fixed-top');
				// remove padding top from body
				document.getElementById('not-found').style.paddingTop = '0';
			}
    },
    onSendMessageSuccess() {
      this.showContactUsModal = false
      this.$swal.fire({
        title: 'Sukses',
        icon: 'success',
        text: 'Pesan berhasil dikirim',
      })
    },
    onSendMessageError(error) {
      this.showContactUsModal = false
      this.$swal.fire({
        title: 'Oops...',
        icon: 'error',
        text: error.message,
      })
    },
  }
}