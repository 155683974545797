export default {
  name: 'footer-component',
  data() {
    return {
      isOnMobile: false,
    }
  },
  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize())
  },
  methods: {
    onResize() {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true
      } else {
        this.isOnMobile = false
      }
    },
  }
}