import navComponent from '../../nav/nav.vue'
import contactUs from '../../../contact-us/contact-us.vue'
import footerComponent from '../../footer/footer.vue'

export default {
  name: 'merchandise',
  metaInfo: {
    title: 'Merchandise'
  },
  components: {
    navComponent,
    contactUs,
    footerComponent
  },
  data() {
    return {
      activeEl: 3,
      showContactUsModal: false,
      isOnMobile: false,
    }
  },
  created() {
    document.documentElement.scrollTop = 0;
  },
  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize)
    if (this.isOnMobile) window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 40) {
				document.getElementById('navbar').classList.add('fixed-top');
				// add padding top to show content behind navbar
				const navbar_height = document.querySelector('.navbar').offsetHeight;
				document.getElementById('merchandise').style.paddingTop = navbar_height + 'px';
			} else {
        document.getElementById('navbar').classList.remove('fixed-top');
				// remove padding top from body
				document.getElementById('merchandise').style.paddingTop = '0';
			}
    },

    onResize() {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true
      } else {
        this.isOnMobile = false
      }
    },

    onContactUsClick() {
      this.showContactUsModal = true
      this.activeEl = 5
    },

    onContactUsClose() {
      this.showContactUsModal = false
      this.activeEl = 3
    },

    buy() {
      window.open('https://www.tokopedia.com/hannahindonesia')
    },

    onSendMessageSuccess() {
      this.showContactUsModal = false
      this.$swal.fire({
        title: 'Sukses',
        icon: 'success',
        text: 'Pesan berhasil dikirim',
      })
    },
    
    onSendMessageError(error) {
      this.showContactUsModal = false
      this.$swal.fire({
        title: 'Oops...',
        icon: 'error',
        text: error.message,
      })
    },
  }
}