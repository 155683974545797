import commonModal from '../common-modal/common-modal.vue'

export default {
  name: 'reset-password',
  metaInfo: {
    title: 'Reset Password',
  },
  components: {
    commonModal,
  },
  data() {
    return {
      isLoading: false,
      isCommonModalShow: false,
      isNewPasswordVisible: false,
      isNewPasswordError: false,
      isConfirmPasswordVisible: false,
      isConfirmPasswordError: false,
      newPasswordErrorMessage: 'New Password is required.',
      confirmPasswordErrorMessage: 'Confirm Password is required.',
      commonModalType: 'info',
      commonModalIcon: 'common-modal-icon.svg',
      commonModalTitle: String,
      commonModalMessage: String,
      commonModalButtonText: 'Login',
      newPassword: {
        value: '',
        isEmpty: false
      },
      confirmPassword: {
        value: '',
        isEmpty: false
      },
      source: undefined,
      ac: undefined,
    }
  },
  created() {
    this.ac = this.$route.query.ac
    this.source = this.$route.query.source
  },
  methods: {
    newPasswordToggle() {
      const passwordField = document.querySelector('#newPassword')
      if (passwordField.getAttribute('type') === 'password') {
        passwordField.setAttribute('type', 'text')
        this.isNewPasswordVisible = true
      } else {
        passwordField.setAttribute('type', 'password')
        this.isNewPasswordVisible = false
      }
    },

    confirmPasswordToggle() {
      const passwordField = document.querySelector('#confirmPassword')
      if (passwordField.getAttribute('type') === 'password') {
        passwordField.setAttribute('type', 'text')
        this.isConfirmPasswordVisible = true
      } else {
        passwordField.setAttribute('type', 'password')
        this.isConfirmPasswordVisible = false
      }
    },

    isInputValid() {
      this.newPassword.isEmpty = this.newPassword.value === ''
      this.confirmPassword.isEmpty = this.confirmPassword.value === ''

      if (this.newPassword.isEmpty || this.confirmPassword.isEmpty) {
        this.newPasswordErrorMessage = 'New Password is required.'
        this.confirmPasswordErrorMessage = 'Confirm Password is required.'
        return false
      } else return true
    },

    isPasswordConfirmMatch() {
      if (this.newPassword.value === this.confirmPassword.value) return true
      else {
        this.confirmPasswordErrorMessage = 'Confirmation Password not match!'
        return false
      }
    },

    onResetPasswordSuccess() {
      this.isCommonModalShow = false
      if (this.source !== undefined && this.source === 'EVENT') this.$router.push({ path: `/user-info/${this.ac}` })
      else this.$router.push({ name: 'login' })
    },

    resetPassword() {
      const requestCode = this.$route.params.requestCode
      
      if (this.isInputValid() && this.isPasswordConfirmMatch()) {
        const payload = {
          requestCode,
          password: this.newPassword.value
        }

        this.isLoading = true
        this.$http.put('/users/reset-password', payload).then(() => {
          this.isLoading = false
          this.isCommonModalShow = true
          this.commonModalTitle = 'Reset Password Berhasil!'
          if (this.source !== undefined && this.source === 'EVENT') {
            this.commonModalMessage = 'Silahkan selesaikan proses gabung dengan teman hannah dengan mengisi form di halaman selanjutnya'
            this.commonModalButtonText = "Lanjutkan"
          } else {
            this.commonModalMessage = 'Silahkan masuk kembali untuk mengakses event dan profile teman hannah!'
          }
        }).catch((err) => {
          this.isLoading = false
          let errMsg = 'Network Error';
          if (err.response) errMsg = err.response.data.message
          this.$swal.fire({
            title: 'Oops...',
            icon: 'error',
            text: errMsg,
          })
        })
      }
    }
  }
}