export default {
  name: 'nav-component',
  props: {
    activeEl: Number,
  },
  data() {
    return {
      navTitle: String,
      navDirection: String,
      userName: String,
      isOnMobile: false,
    }
  },
  created() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (userData) {
      this.navTitle = `Hello, ${userData.name}!`
      this.navDirection = '/profile'
      this.userName = userData.name
    } else {
      this.navTitle = 'Login'
      this.navDirection = '/login'
    }
  },
  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize())
  },
  methods: {
    onResize() {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true
      } else {
        this.isOnMobile = false
      }
    },
    onLogout() {
      localStorage.removeItem("userData")
      location.reload()
    },
    directToLogin() {
      this.$router.push({ name: 'login' })
    },
    directToProfile() {
      this.$router.push({ name: 'profile' })
    },
  },
}