import navComponent from '../index/nav/nav.vue'
import contactUs from '../contact-us/contact-us.vue'
import footerComponent from '../index/footer/footer.vue'
const moment = require('moment');

export default {
  name: 'profile',
  components: {
    navComponent,
    contactUs,
    footerComponent,
  },
  data() {
    return {
      userData: null,
      activeEl: 0,
      showContactUsModal: false,
      events: [],
      isOnMobile: false,
      countDownTimer: [],
    }
  },
  created() {
    document.documentElement.scrollTop = 0;
    window.addEventListener('scroll', this.handleScroll);
    this.userData = JSON.parse(localStorage.getItem('userData'))
    const sinceYear = this.userData.createdAt.split(' ')[0].split('-')[0]
    this.userData.sinceYear = sinceYear
  },
  mounted() {
    this.onResize()
    this.getHistoryEvent()
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    onResize() {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true
      } else {
        this.isOnMobile = false
      }
    },
    handleScroll() {
      if (window.scrollY > 40) {
				document.getElementById('navbar').classList.add('fixed-top');
				// add padding top to show content behind navbar
				const navbar_height = document.querySelector('.navbar').offsetHeight;
				document.getElementById('profile').style.paddingTop = navbar_height + 'px';
			} else {
        document.getElementById('navbar').classList.remove('fixed-top');
				// remove padding top from body
				document.getElementById('profile').style.paddingTop = '0';
			}
    },
    onContactUsClick() {
      this.showContactUsModal = true
      this.activeEl = 5
    },
    onContactUsClose() {
      this.showContactUsModal = false
      this.activeEl = 0
    },
    getHistoryEvent() {
      const accessToken = JSON.parse(localStorage.getItem('userData')).accessToken
      this.$http.get('/program/registered-history?size=5', {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      }).then((res) => {
        this.events = res.data.data.map((e) => {
          const program = e.program
          const transaction = e.transaction
          const dateTime = program.date.split(' ')
          program.date = dateTime[0]
          program.time = dateTime[1]
          program.poster = `${process.env.VUE_APP_BASE_STORAGE_URL}/${program.poster}`

          if (transaction) {
            e.transaction.expiredAt = moment(e.transaction.expiredAt).utc(true).utcOffset(0).format('YYYY-MM-DD HH:mm:ss');
          }
          return e
        })
      }).catch((err) => {
        const message = err.response.data.message
        console.log(message);
      })
    },
    onEventClick(event) {
      if (event.transaction) this.$router.push({ path: `/payment/${event.bookId}` })
      else this.$router.push({ path: `/program/event/${event.program.id}` })
    },
    onLogout() {
      localStorage.removeItem("userData")
      this.$router.push({ name: 'index' })
    },
    onSendMessageSuccess() {
      this.showContactUsModal = false
      this.$swal.fire({
        title: 'Sukses',
        icon: 'success',
        text: 'Pesan berhasil dikirim',
      })
    },
    onSendMessageError(error) {
      this.showContactUsModal = false
      this.$swal.fire({
        title: 'Oops...',
        icon: 'error',
        text: error.message,
      })
    },
  }
}