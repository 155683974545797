export default {
  name: 'common-modal',
  props: {
    type: String,
    icon: String,
    title: String,
    message: String,
    confirmButtonText: String,
    cancelButtonText: String
  }
}