import { render, staticRenderFns } from "./ourteam.html?vue&type=template&id=0f3e2278&scoped=true&"
import script from "./ourteam.js?vue&type=script&lang=js&"
export * from "./ourteam.js?vue&type=script&lang=js&"
import style0 from "./ourteam.css?vue&type=style&index=0&id=0f3e2278&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f3e2278",
  null
  
)

export default component.exports