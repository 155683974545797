import axios from '../../remote/axios/index.js'

export default {
  registerProgram({ commit }, { id, notes = undefined }) {
    const accessToken = JSON.parse(localStorage.getItem('userData')).accessToken
    commit('setProgramUIState', { status: 'LOADING' })
    const formData = new FormData()
    formData.append('programId', id)
    if (notes) formData.append('notes', notes)
    axios.post('/program/register', formData, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    }).then((res) => {
      commit('setProgramUIState', { status: 'SUCCESS', data: res.data.data })
    }).catch((err) => {
      const message = err.response.data.message
      commit('setProgramUIState', { status: 'FAILED', message: message })
    })
  }
}