function toIdr(value) {
  return new Intl.NumberFormat("id-ID", {
    maximumSignificantDigits: 3
  }).format(value);
}

function countDownTimer(targetTime, callback) {
  const interval = setInterval(() => {
    const now = new Date().getTime()
    const distance = targetTime - now

    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    callback(hours, minutes, seconds, false)

    if (distance < 0) {
      callback(0, 0, 0, true)
      clearInterval(interval);
    }
  }, 1000)


}

export { toIdr, countDownTimer }