import axios from '../../remote/axios/index.js'

export default {
  login({ commit }, payload) {
    commit('setLoginUIState', { status: 'LOADING' })
    axios.post('/users/login', payload).then((res) => {
      this.isLoading = false
      const data = res.data.data
      this.isEmailError = false
      this.isPasswordError = false
      if (data.marital_status === null) {
        commit('setLoginUIState', { status: 'REDIRECT', redirect: `/user-info/${data.activationCode}` })
      } else {
        localStorage.setItem('userData', JSON.stringify(data))
        commit('setLoginUIState', { status: 'SUCCESS' })
      }
    }).catch((err) => {
      this.isLoading = false
      const statusCode = err.response.status
      const message = err.response.data.message
      const data = err.response.data.data
      commit('setLoginUIState', { status: 'FAILED', code: statusCode, message, data })
    })
  }
}