import Vue from 'vue'
import VueRouter from 'vue-router'
import login from './components/login/login.vue'
import register from './components/register/register.vue'
import emailVerification from './components/email-verification/email-verification.vue'
import index from './components/index/index.vue'
import notFoundPage from './components/index/not-found/not-found.vue'
import event from './components/index/program/event/event.vue'
import ourteam from './components/ourteam/ourteam.vue'
import detailEvent from './components/index/program/event/detail-event/detail-event.vue'
import masterClass from './components/index/program/master-class/master-class.vue'
import merchandise from './components/index/program/merchandise/merchandise.vue'
import profile from './components/profile/profile.vue'
import eventHistory from './components/profile/event-history/event-history.vue'
import forgetPassword from './components/forget-password/forget-password.vue'
import resetPassword from './components/reset-password/reset-password.vue'
import userInfo from './components/register/form-user-info/form-user-info.vue'
import payment from './components/payment/payment.vue'
import paymentSuccess from './components/payment/success/payment-success.vue'

Vue.use(VueRouter)

const ifNotLoggedIn = (to, from, next) => {
  if (!localStorage.getItem('userData')) {
    next()
    return
  }
  next('/')
}

const ifLoggedIn = (to, from, next) => {
  if (!localStorage.getItem('userData')) {
    next('/login')
    return
  }
  next()
}

const routes = [
  {
    name: 'login',
    path: '/login',
    component: login,
    beforeEnter: ifNotLoggedIn,
    // meta: {
    //   title: "Masuk | Hannah Indonesia"
    // }
  },
  {
    name: 'register',
    path: '/register',
    component: register,
    beforeEnter: ifNotLoggedIn,
  },
  {
    name: 'forget-password',
    path: '/forget-password',
    component: forgetPassword,
  },
  {
    name: 'reset-password',
    path: '/reset-password/:requestCode',
    component: resetPassword,
  },
  {
    name: 'form-user-info',
    path: '/user-info/:activationCode',
    component: userInfo,
  },
  {
    name: 'profile',
    path: '/profile',
    component: profile,
    beforeEnter: ifLoggedIn,
  },
  {
    name: 'event-history',
    path: '/profile/event-history',
    component: eventHistory,
    beforeEnter: ifLoggedIn,
  },
  {
    name: 'email-verification',
    path: '/email-verification/:activationCode',
    component: emailVerification,
  },
  {
    name: 'index',
    path: '/',
    component: index,
  },
  {
    name: 'ourteam',
    path:'/our-team',
    component: ourteam,
  },
  {
    name: 'event',
    path: '/program/event',
    component: event,
  },
  {
    name: 'detail-event',
    path: '/program/event/:id',
    component: detailEvent,
  },
  {
    name: 'master-class',
    path: '/program/master-class',
    component: masterClass,
  },
  {
    name: 'merchandise',
    path: '/merchandise',
    component: merchandise,
  },
  {
    name: 'payment',
    path: '/payment/:id',
    component: payment,
  },
  {
    name: 'payment-success',
    path: '/payment/:bookId/success',
    component: paymentSuccess,
  },
  {
    path: "*",
    component: notFoundPage
  }
]

const router = new VueRouter({ mode: 'history', routes })

// const DEFAULT_TITLE = 'Hannah Indonesia';
// router.afterEach((to) => {
//     Vue.nextTick(() => {
//         document.title = to.meta.title || DEFAULT_TITLE;
//     });
// });

export default router