import commonModal from '../common-modal/common-modal.vue'

export default {
  name: 'forget-password',
  metaInfo: {
    title: 'Lupa Password',
  },
  components: {
    commonModal,
  },
  data() {
    return {
      isLoading: false,
      isEmailError: false,
      emailErrorMessage: 'Email is required.',
      commonModal: {
        isShow: false,
        type: 'info',
        icon: 'email-verification-icon.svg',
        title: String,
        message: String,
        confirmButtonText: 'Tutup',
      },
      email: {
        value: '',
        isEmpty: false
      },
    }
  },
  methods: {
    isInputValid() {
      this.email.isEmpty = this.email.value === ''

      if (this.email.isEmpty) {
        this.emailErrorMessage = 'Email is required.'
        return false
      } else return true
    },

    requestResetPassword() {
      if (this.isInputValid()) {
        const payload = {
          email: this.email.value,
        }

        this.isLoading = true
        this.$http.post('/users/request-reset-password', payload).then(() => {
          this.isLoading = false
          this.commonModal.isShow = true
          this.commonModal.title = 'Request Telah Dikirim'
          this.commonModal.message = 'Cek <b>kotak masuk/spam email</b> kamu dan <b>ikuti instruksi</b> reset password di sana ya!'
        }).catch((err) => {
          this.isLoading = false
          if (err.response) {
            const errMsg = err.response.data.message
            if (errMsg.includes('email')) {
              this.isEmailError = true
              this.emailErrorMessage = errMsg
            } else {
              this.$swal.fire({
                title: 'Oops...',
                icon: 'error',
                text: errMsg,
              })
            }
          } else {
            this.$swal.fire({
              title: 'Oops...',
              icon: 'error',
              text: 'Network Error',
            })
          }
        })
      }
    }
  }
}