export default {
  name: 'contact-us',
  data() {
    return {
      isLoading: false,
      name: {
        value: '',
        isEmpty: false
      },
      email: {
        value: '',
        isEmpty: false
      },
      message: {
        value: '',
        isEmpty: false
      }
    }
  },
  methods: {
    isInputValid() {
      this.name.isEmpty = this.name.value === ''
      this.email.isEmpty = this.email.value === ''
      this.message.isEmpty = this.message.value === ''

      if (this.name.isEmpty || this.email.isEmpty || this.message.isEmpty) return false
      else return true
    },
    send() {
      if (this.isInputValid()) {
        const payload = {
          name: this.name.value,
          email: this.email.value,
          message: this.message.value,
        }

        this.isLoading = true
        this.$http.post('/message', payload).then(() => {
          this.isLoading = false
          this.$emit('onSuccess')
        }).catch((err) => {
          this.isLoading = false
          let statusCode = 0
          let message = "Network error"
          if (err.response) {
            statusCode = err.response.status,
            message = err.response.data.message
          }
          const error = { statusCode, message }
          this.$emit('onError', error)
        })
      }
    }
  }
}