import commonModal from '../common-modal/common-modal.vue'

export default {
  name: 'register',
  components: {
    commonModal,
  },
  data() {
    return {
      isLoading: false,
      commonModal: {
        isShow: false,
        type: 'info',
        icon: 'email-verification-icon.svg',
        title: String,
        message: String,
        confirmButtonText: 'Tutup',
      },
      isCreatePasswordShow: false,
      isConfirmPasswordShow: false,
      confirmPasswordErrorMessage: 'Ulangi Password harus diisi.',
      redirect: undefined,
      user: {
        name: {
          value: '',
          isEmpty: false
        },
        phoneNumber: {
          value: '',
          isEmpty: false,
          isError: false,
          errorMessage: '',
        },
        age: {
          value: '',
          isEmpty: false,
          isError: false,
          errorMessage: '',
        },
        domicile: {
          value: '',
          isEmpty: false,
        },
        marital_status: {
          value: 'Status Perkawinan',
          isEmpty: false,
        },
        num_of_children: {
          value: '',
          isEmpty: false,
        },
        child_age: {
          value: [],
          isEmpty: false,
        },
        email: {
          value: '',
          isEmpty: false,
          isError: false,
          errorMessage: '',
        },
        createPassword: {
          value: '',
          isEmpty: false
        },
        confirmPassword: {
          value: '',
          isEmpty: false
        }
      }
    }
  },
  created() {
    this.redirect = this.$route.query.redirect
  },
  methods: {
    createPasswordToggle() {
      const passwordField = document.querySelector('#create_password')
      if (passwordField.getAttribute('type') === 'password') {
        passwordField.setAttribute('type', 'text')
        this.isCreatePasswordShow = true
      } else {
        passwordField.setAttribute('type', 'password')
        this.isCreatePasswordShow = false
      }
    },

    confirmPasswordToggle() {
      const passwordField = document.querySelector('#confirm_password')
      if (passwordField.getAttribute('type') === 'password') {
        passwordField.setAttribute('type', 'text')
        this.isConfirmPasswordShow = true
      } else {
        passwordField.setAttribute('type', 'password')
        this.isConfirmPasswordShow = false
      }
    },

    isInputValid() {
      this.user.name.isEmpty = this.user.name.value === ''
      this.user.age.isEmpty = this.user.age.value === ''
      this.user.domicile.isEmpty = this.user.domicile.value === ''
      this.user.marital_status.isEmpty = this.user.marital_status.value === 'Status Perkawinan'
      this.user.num_of_children.isEmpty = this.user.num_of_children.value === ''
      this.user.child_age.isEmpty = this.user.child_age.value.length === 0
      this.user.email.isEmpty = this.user.email.value === ''
      this.user.phoneNumber.isEmpty = this.user.phoneNumber.value === ''
      this.user.createPassword.isEmpty = this.user.createPassword.value === ''
      this.user.confirmPassword.isEmpty = this.user.confirmPassword.value === ''

      if (this.user.name.isEmpty || this.user.age.isEmpty || this.user.domicile.isEmpty || this.user.marital_status.isEmpty || this.user.email.isEmpty || this.user.phoneNumber.isEmpty || this.user.createPassword.isEmpty || this.user.confirmPassword.isEmpty) {
        this._scrollTo('email')
        if (this.user.email.isEmpty) this.user.email.errorMessage = 'Email harus diisi.'
        if (this.user.phoneNumber.isEmpty) this.user.phoneNumber.errorMessage = 'Nomor Telepon Aktif harus diisi.'
        if (this.user.age.isEmpty) this.user.age.errorMessage = 'Usia harus diisi.'

        return false
      } else {
        if (this.user.marital_status.value === 'MENIKAH') {
          if (this.user.num_of_children.isEmpty || this.user.child_age.isEmpty) return false
        }
        return true
      }
    },

    isPasswordConfirmMatch() {
      if (this.user.createPassword.value === this.user.confirmPassword.value) return true
      else {
        this.confirmPasswordErrorMessage = 'Password yang anda masukkan tidak sama'
        return false
      }
    },

    registerUser() {
      if (this.isInputValid() && this.isPasswordConfirmMatch()) {
        const formData = new FormData()
        formData.append('name', this.user.name.value)
        formData.append('email', this.user.email.value)
        formData.append('phoneNumber', this.user.phoneNumber.value)
        formData.append('age', this.user.age.value)
        formData.append('domicile', this.user.domicile.value)
        formData.append('marital_status', this.user.marital_status.value)
        formData.append('password', this.user.createPassword.value)
        if (this.user.marital_status.value === 'MENIKAH') {
          formData.append('num_of_children', this.user.num_of_children.value)

          const childAge = JSON.stringify(this.user.child_age.value)
          formData.append('child_age', childAge)
        }
        if (this.redirect && this.redirect !== '/' && this.redirect !== '/login') formData.append('redirect', this.redirect)
    
        this.isLoading = true
        this.$http.post('/users', formData).then(() => {
          this.isLoading = false
          this.showCommonModal({
            type: 'info',
            icon: 'email-verification-icon.svg',
            title: 'Konfirmasi Akun Anda',
            message: 'Cek <b>kotak masuk/spam email</b> kamu dan <b>ikuti instruksi</b> konfirmasi akun lewat email ya!',
            confirmButtonText: 'Tutup',
          })
        }).catch((err) => {
          this.isLoading = false
          const errMsg = err.response.data.message
          const data = err.response.data.data
          if (data) {
            this._scrollTo(data[0].field)
            this.user.email.isError = data.some((d) => d.field === 'email')
            if (this.user.email.isError) this.user.email.errorMessage = data.find((d) => d.field === 'email').message
            
            this.user.phoneNumber.isError = data.some((d) => d.field === 'phoneNumber')
            if (this.user.phoneNumber.isError) this.user.phoneNumber.errorMessage = data.find((d) => d.field === 'phoneNumber').message

            this.user.age.isError = data.some((d) => d.field === 'age')
            if (this.user.age.isError) this.user.age.errorMessage = data.find((d) => d.field === 'age').message
          } else {
            this.user.phoneNumber.isError = false
            this.user.age.isError = false
            if (errMsg.includes('Email')) {
              this._scrollTo('email')
              this.user.email.isError = true
              this.user.email.errorMessage = errMsg
            } else this.user.email.isError = false
          }
          console.log(errMsg);
        })
      } else console.log('Invalid Input');
    },

    _scrollTo(field) {
      document.getElementById(field).scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    },

    showCommonModal({type, icon, title, message, confirmButtonText}) {
      this.commonModal.isShow = true
      this.commonModal.type = type
      this.commonModal.icon = icon
      this.commonModal.title = title
      this.commonModal.message = message,
      this.commonModal.confirmButtonText = confirmButtonText
    },

    onCloseCommonModal() {
      this.commonModal.isShow = false
      setTimeout(() => {
        if (this.redirect) this.$router.push({ path: this.redirect })
        else this.$router.push({ name: 'login' })
      }, 500)
    },
  }
}