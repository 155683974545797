import commonModal from '../common-modal/common-modal.vue'

export default {
  name: 'login',
  metaInfo: {
    title: 'Masuk',
  },
  components: {
    commonModal
  },
  data() {
    return {
      from: null,
      isLoading: false,
      isPasswordVisible: false,
      isPasswordError: false,
      isEmailError: false,
      passwordErrorMessage: 'Password harus diisi',
      emailErrorMessage: 'Email harus diisi',
      commonModal: {
        isShow: false,
        type: 'info',
        icon: 'email-verification-icon.svg',
        title: String,
        message: String,
        confirmButtonText: 'Tutup',
      },
      user: {
        email: {
          value: '',
          isEmpty: false
        },
        password: {
          value: '',
          isEmpty: false
        }
      }
    }
  },
  beforeRouteEnter(_, from, next) {
    next(vm => {
      vm.from = from
    })
  },
  created() {
    const status = this.$route.query.status
    if (this.$store.getters['emailVerification/isModalShow']) {
      const commonModalProperty = {
        type: 'info'
      }
      if (status === 'success') {
        commonModalProperty['icon'] = 'ic-hannah-selamat-bergabung.svg'
        commonModalProperty['title'] = 'Selamat Datang, Teman Hannah!'
        commonModalProperty['message'] = 'Selamat datang, selamat bergabung, semangat meraih ilmu bersama Hannah Indonesia.'
      } else {
        commonModalProperty['icon'] = 'ic_modal_error.svg'
        commonModalProperty['title'] = 'Verifikasi Email Gagal'
        commonModalProperty['message'] = this.$store.getters['emailVerification/getMessage']
      }
      setTimeout(() => this.showCommonModal(commonModalProperty), 500)
    }
  },
  computed: {
    loginUIState() {
      return this.$store.getters['login/loginUIState']
    }
  },
  watch: {
    loginUIState(state) {
      const { status, code = undefined, message = undefined, data = undefined, redirect: to = undefined } = state
      const redirect = this.$route.query.redirect
      switch(status) {
        case 'LOADING':
          this.isLoading = true
          break
        case 'SUCCESS':
          this.isLoading = false
          if (redirect !== undefined) this.$router.push({ path: redirect })
          else if (this.from.name !== null) {
            if (this.from.name === 'detail-event' || this.from.name === 'event' || this.from.name === 'index') this.$router.push({ path: this.from.path })
            else this.$router.push({ name: 'index' })
          } else this.$router.push({ name: 'index' })
          break
        case 'FAILED':
          this.isLoading = false
          switch(code) {
            case 400:
              if (data) {
                this.isEmailError = data.filter((d) => {
                  if (d.field === 'email') this.emailErrorMessage = d.message
                  return d.field === 'email'
                }).length > 0
              }
              break;
            case 401:
              this.isEmailError = false
              this.isPasswordError = true
              this.passwordErrorMessage = message
              break
            case 403:
              this.isEmailError = false
              this.isPasswordError = false
              this.showCommonModal({
                type: 'info',
                icon: 'email-verification-icon.svg',
                title: 'Konfirmasi Akun Anda',
                message: 'Cek <b>kotak masuk/spam email</b> kamu dan <b>ikuti instruksi</b> konfirmasi akun lewat email ya!'
              })
              break
            case 404:
              this.isEmailError = true
              this.isPasswordError = false
              this.emailErrorMessage = message
              break
          }
          break
        case 'REDIRECT':
          this.isLoading = false
          this.$router.push({ path: to })
          break
      }
    }
  },
  methods: {
    passwordToggle() {
      const passwordField = document.querySelector('#password')
      if (passwordField.getAttribute('type') === 'password') {
        passwordField.setAttribute('type', 'text')
        this.isPasswordVisible = true
      } else {
        passwordField.setAttribute('type', 'password')
        this.isPasswordVisible = false
      }
    },

    showCommonModal({type, icon, title, message}) {
      this.commonModal.isShow = true
      this.commonModal.type = type
      this.commonModal.icon = icon
      this.commonModal.title = title
      this.commonModal.message = message
    },

    isInputValid() {
      this.user.email.isEmpty = this.user.email.value === ''
      this.user.password.isEmpty = this.user.password.value === ''

      if (this.user.email.isEmpty || this.user.password.isEmpty) {
        this.emailErrorMessage = 'Email harus diisi'
        this.passwordErrorMessage = 'Password harus diisi'
        return false
      } else return true
    },

    login() {
      if (this.isInputValid()) {
        const payload = {
          email: this.user.email.value,
          password: this.user.password.value
        }

        this.$store.dispatch('login/login', payload)
      }
    }
  }
}