import mutations from './mutations.js'
import getters from './getters.js'

export default {
  namespaced: true,
  state: {
    isModalShow: false,
    message: String
  },
  mutations,
  getters
}