import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: 'register-event',
  props: {
    programId: String,
  },
  components: {
    DatePicker
  },
  data() {
    return {
      isLoading: false,
      isResponseError: false,
      errorMessage: '',
      payload: {
        name: {
          value: '',
          isEmpty: false
        },
        email: {
          value: '',
          isEmpty: false
        },
        birthDate: {
          value: '',
          isEmpty: false
        },
        gender: 'P',
        profession: {
          value: 'Pilih Pekerjaan',
          isEmpty: false
        },
        otherProfession: {
          value: '',
          isEmpty: false
        },
        domicile: {
          value: 'Domisili',
          isEmpty: false
        },
        otherDomicile: {
          value: '',
          isEmpty: false
        },
        phoneNumber: {
          value: '',
          isEmpty: false
        },
        deviceOS: 'iOS',
      },
      isPrivacyPolicyChecked: false,
    }
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (userData) {
      this.payload.name.value = userData.name
      this.payload.email.value = userData.email
      this.payload.phoneNumber.value = userData.phoneNumber
    }
  },
  methods: {
    isInputValid() {
      this.payload.name.isEmpty = this.payload.name.value === ''
      this.payload.email.isEmpty = this.payload.email.value === ''
      this.payload.birthDate.isEmpty = this.payload.birthDate.value === ''
      this.payload.phoneNumber.isEmpty = this.payload.phoneNumber.value === ''
      this.payload.domicile.isEmpty = this.payload.domicile.value === 'Domisili'
      this.payload.otherDomicile.isEmpty = this.payload.otherDomicile.value === ''
      this.payload.profession.isEmpty = this.payload.profession.value === 'Pilih Pekerjaan'
      this.payload.otherProfession.isEmpty = this.payload.otherProfession.value === ''

      if (this.payload.name.isEmpty || this.payload.email.isEmpty || this.payload.birthDate.isEmpty || this.payload.domicile.isEmpty || this.payload.phoneNumber.isEmpty || this.payload.profession.isEmpty) return false
      else {
        if (this.payload.domicile.value === 'Lainnya' && this.payload.otherDomicile.isEmpty) return false
        if (this.payload.profession.value === 'Lainnya' && this.payload.otherProfession.isEmpty) return false
        return true 
      }
    },

    registerEvent() {
      if (this.isInputValid()) {
        const domicile = this.payload.domicile.value === 'Lainnya' ? this.payload.otherDomicile.value : this.payload.domicile.value
        const profession = this.payload.profession.value === 'Lainnya' ? this.payload.otherProfession.value : this.payload.profession.value
        const payload = {
          programId: this.programId,
          name: this.payload.name.value,
          email: this.payload.email.value,
          birthDate: this.payload.birthDate.value,
          gender: this.payload.gender,
          profession,
          domicile,
          phoneNumber: this.payload.phoneNumber.value,
          deviceOS: this.payload.deviceOS,
        }

        this.isLoading = true
        this.$http.post('/limited-program/register', payload).then(() => {
          this.isLoading = false
          this.$emit('onRegisterSuccess')
        }).catch((err) => {
          this.isLoading = false
          this.isResponseError = true
          this.errorMessage = err.response.data.message
        })
      }
    },
  }
}