import navComponent from '../../nav/nav.vue'
import contactUs from '../../../contact-us/contact-us.vue'
import footerComponent from '../../footer/footer.vue'

export default {
  name: 'master-class',
  metaInfo: {
    title: 'Masterclass',
  },
  components: {
    navComponent,
    contactUs,
    footerComponent
  },
  data() {
    return {
      activeEl: 3,
      showContactUsModal: false,
    }
  },
  created() {
    document.documentElement.scrollTop = 0;
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 40) {
				document.getElementById('navbar').classList.add('fixed-top');
				// add padding top to show content behind navbar
				const navbar_height = document.querySelector('.navbar').offsetHeight;
				document.getElementById('master-class').style.paddingTop = navbar_height + 'px';
			} else {
        document.getElementById('navbar').classList.remove('fixed-top');
				// remove padding top from body
				document.getElementById('master-class').style.paddingTop = '0';
			}
    },

    onContactUsClick() {
      this.showContactUsModal = true
      this.activeEl = 5
    },

    onContactUsClose() {
      this.showContactUsModal = false
      this.activeEl = 3
    },

    onSendMessageSuccess() {
      this.showContactUsModal = false
      this.$swal.fire({
        title: 'Sukses',
        icon: 'success',
        text: 'Pesan berhasil dikirim',
      })
    },
    
    onSendMessageError(error) {
      this.showContactUsModal = false
      this.$swal.fire({
        title: 'Oops...',
        icon: 'error',
        text: error.message,
      })
    },
  }
}