import navComponent from '../../../nav/nav.vue'
import contactUs from '../../../../contact-us/contact-us.vue'
import commonModal from '../../../../common-modal/common-modal.vue'
import registerEvent from '../register-event/register-event.vue'
import formAddress from '../../../../profile/form-address/form-address.vue'
import footerComponent from '../../../footer/footer.vue'
import { toIdr } from '../../../../../helper/currency-helper'

export default {
  name: 'detail-event',
  metaInfo: {
    title: 'Detail Event',
  },
  components: {
    navComponent,
    contactUs,
    commonModal,
    registerEvent,
    formAddress,
    footerComponent
  },
  data() {
    return {
      isOnMobile: false,
      from: null,
      activeEl: 3,
      showModalRegisterEvent: false,
      showFormAddress: false,
      showContactUsModal: false,
      commonModal: {
        isShow: false,
        type: 'info',
        icon: 'ic-success-register-program.svg',
        title: String,
        message: String,
        confirmButtonText: 'Tutup',
        direction: null
      },
      redirect: null,
      event: {},
      isPrerequisiteAvailable: false,
      btnRegisterLoading: false,
      isLoading: true,
      isError: false,
    }
  },
  beforeRouteEnter(_, from, next) {
    next(vm => {
      vm.from = from
    })
  },
  created() {
    document.documentElement.scrollTop = 0
    window.addEventListener('scroll', this.handleScroll)
  },
  mounted() {
    this.onResize()
    const eventId = this.$route.params.id
    this.getEventById(eventId)

    if (this.from !== null) {
      const path = this.from.path
      const query = this.from.query
      if (path === '/login' && Object.keys(query).length !== 0) setTimeout(() => this.showCommonModal({ 
        type: 'info',
        icon: 'ic-success-register-program.svg',
        title: 'Yuk, selesaikan registrasi event mu!',
        message: 'Jangan lupa selesaikan registrasi event mu ya, agar bisa mengikuti event yg sedang berlangsung',
        confirmButtonText: 'Lanjutkan Registrasi Event'
      }), 1000)
    }
  },
  computed: {
    programUIState() {
      return this.$store.getters['program/programUIState']
    }
  },
  watch: {
    programUIState(state) {
      const { status, data } = state
      switch(status) {
        case 'LOADING':
          this.btnRegisterLoading = true
          break
        case 'SUCCESS':
          this.btnRegisterLoading = false
          if (this.event.price !== 0) {
            this.$router.push({ name: 'payment', params: { id: data.bookId } })
          } else {
            this.showCommonModal({
              type: 'info',
              icon: 'ic-success-register-program.svg',
              title: 'Berhasil Daftar!',
              message: 'Terima kasih telah mengambil bagian di acara yang sedang berlangsung. Semoga ilmu nya bermanfaat!',
              confirmButtonText: 'Lihat Riwayat Event',
              direction: 'profile',
            })
          }
          break
        case 'FAILED':
          this.btnRegisterLoading = false
          this.showCommonModal({
            type: 'info',
            icon: 'ic-success-register-program.svg',
            title: 'Sudah Terdaftar!',
            message: 'Anda sudah terdaftar pada program ini.',
            confirmButtonText: 'Lihat Riwayat Event',
            direction: 'profile',
          })
          break
      }
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    onResize() {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true
      } else {
        this.isOnMobile = false
      }
    },
    handleScroll() {
      if (window.scrollY > 40) {
				document.getElementById('navbar').classList.add('fixed-top');
				// add padding top to show content behind navbar
				const navbar_height = document.querySelector('.navbar').offsetHeight;
				document.getElementById('event-detail').style.paddingTop = navbar_height + 'px';
			} else {
        document.getElementById('navbar').classList.remove('fixed-top');
				// remove padding top from body
				document.getElementById('event-detail').style.paddingTop = '0';
			}
    },

    showCommonModal({ type, icon, title, message, confirmButtonText, direction = null}) {
      this.commonModal.isShow = true
      this.commonModal.type = type
      this.commonModal.icon = icon
      this.commonModal.title = title
      this.commonModal.message = message
      this.commonModal.confirmButtonText = confirmButtonText
      this.commonModal.direction = direction
    },

    closeCommonModal() {
      this.commonModal.isShow = false
      if (this.commonModal.direction !== null) {
        this.$router.push({ name: this.commonModal.direction })
        this.commonModal.direction = null
      }
    },

    onBtnRegisterClick() {
      const userData = localStorage.getItem('userData')
      if (this.event.limited) {
        this.showModalRegisterEvent = true
      } else {
        if (!userData) this.$router.push({ name: 'login', query: { redirect: this.$route.path } })
        else {
          if (this.event.withMerchandise) this.showFormAddress = true
          else this.$store.dispatch('program/registerProgram', { id: this.event.id })
        }
      }
    },

    onAddAddressSuccess(notes) {
      this.showFormAddress = false
      this.$store.dispatch('program/registerProgram', { id: this.event.id, notes })
    },

    onRegisterEventSuccess() {
      this.showModalRegisterEvent = false
      this.showCommonModal({
        type: 'info',
        icon: 'common-modal-icon.svg',
        title: 'Selamat, anda berhasil daftar!',
        message: 'Cek <b>kotak masuk/spam email</b> kamu untuk mendapat informasi detil seputar event ini yaa!',
        confirmButtonText: 'Tutup'
      })
    },

    onContactUsClick() {
      this.showContactUsModal = true
      this.activeEl = 5
    },

    onContactUsClose() {
      this.showContactUsModal = false
      this.activeEl = 3
    },

    showPopupRegisterAccount() {
      if (localStorage.getItem('userData')) {
        this.showCommonModal({
          type: 'info',
          icon: 'ic-hannah-selamat-bergabung.svg',
          title: 'Terima Kasih Anda Sudah Gabung Jadi Teman Hannah',
          message: 'Anda telah berhgabung menjadi Teman Hannah, semangat meraih ilmu bersama Hannah Indonesia.',
          confirmButtonText: 'Tutup'
        })
      } else this.$router.push({ name: 'register', query: { redirect: this.$route.path } })
    },

    getEventById(eventId) {
      this.isLoading = true
      this.$http.get(`/program/${eventId}`).then((res) => {
        const data = res.data.data
        const dateTime = data.date.split(' ')
        this.event = data
        this.event.date = dateTime[0]
        this.event.time = dateTime[1]
        this.event.needs = JSON.parse(this.event.needs)
        this.event.poster = `${process.env.VUE_APP_BASE_STORAGE_URL}/${this.event.poster}`
        this.isPrerequisiteAvailable = data.needs.length !== 0
        if (this.event.price !== 0) this.event.price = toIdr(this.event.price)

        switch (this.event.location) {
          case 'ONSITE':
            this.event.location = this.event.eventAddress
            break
          case 'ONLINE':
            this.event.location = 'Online'
            break
          case 'ONSITE_ONLINE':
            this.event.location = `${this.event.eventAddress} & Online, tautan akan dikirimkan via Email`
            break
        }

        this.isLoading = false
        this.isError = false
      }).catch((err) => {
        console.log(err);
        // this.$http.post('https://hannahindonesia.com:5000/program/log', { log: err })
        this.isLoading = false
        this.isError = true
        let errorMessage = "Network error";
        if (err.response) errorMessage = err.response.data.message
        this.$swal.fire({
          title: 'Oops...',
          icon: 'error',
          text: errorMessage,
        })
      })
    },
    
    onSendMessageSuccess() {
      this.showContactUsModal = false
      this.$swal.fire({
        title: 'Sukses',
        icon: 'success',
        text: 'Pesan berhasil dikirim',
      })
    },
    
    onSendMessageError(error) {
      this.showContactUsModal = false
      this.$swal.fire({
        title: 'Oops...',
        icon: 'error',
        text: error.message,
      })
    },
  }
}